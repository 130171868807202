import React from "react";
import { Link, useLocation } from "react-router-dom";
import './Hero.css';

export default function Hero({ data }) {
    const location = useLocation();

    return (
        <div className={location.pathname === "/" ? 'hero-home container' : 'hero-contact container'}>
            <div className="hero-text">
                <h1>{data.header}</h1>
                {data.subHeader && <p>{data.subHeader}</p>}
                {data.path && <Link className="dark-button" to={data.path}>{data.label}</Link>}
            </div>
        </div>
    );
}