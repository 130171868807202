import React, { useState } from "react";
import { data } from "../Data/AccordionData";
import { IconContext } from "react-icons";
import { FiPlus, FiMinus } from "react-icons/fi";
import "./Accordion.css";

export default function Accordion() {
    const [clicked, setClicked] = useState(null);

    const toggle = (index) => {
        if (clicked === index) return setClicked(null);
        setClicked(index);
    }

    return (
        <IconContext.Provider value={{ size: '25px' }}>
            <div className="accordion-section">
                <div className="accordion-container">
                    {
                        data.map((item, index) => (
                            <div className="item">
                                <div className="question" onClick={() => { toggle(index) }}>
                                    <h2>{item.question}</h2>
                                    <span>{ clicked === index ? <FiMinus/> : <FiPlus/> }</span>
                                </div>
                                <div className={ clicked === index ? 'answer show' : 'answer'}>{item.answer}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </IconContext.Provider>
    );
}