import React from "react";
import './AboutSection.css';
import { Link } from "react-router-dom";
import sectionImage from "../images/cleaning2.jpg"

export default function AboutSection() {
    return (
        <div className="about">
            <div className="about-left">
                <img className="about-img" src={sectionImage} alt="employees" />
            </div>
            <div className="about-right">
                <h3>About Us</h3>
                <h2>Who We Are</h2>
                <p>
                    HouseHelp Ltd is a Rwandan-based startup dedicated to revolutionizing the domestic service industry. Our mission is
                    to provide reliable, professional, and customer-centric household services while empowering workers and promoting social impact.
                    HouseHelp was born out of a vision to address the inherent deficiencies in the informal domestic service sector in Rwanda.
                </p>
                <p>
                    HouseHelp offers a range of household services including housekeeping, childcare, cooking, and gardening.
                </p>
                <p>
                    We connect households with trained and vetted houseworkers. Through our extensive network and rigorous vetting process, we 
                    ensure seamless connections and prioritizing ongoing support for clients and workers alike.
                </p>
                <button className="button"><Link to="/about">Learn More</Link></button>
            </div>
        </div>
    );
}