import React from "react";
import { data } from "../Data/GoalsData";
import "./Goals.css";

export default function Goals () {
    return (
        <div className="goals">
            <div>
                <p>With over 50% of domestic workers facing job insecurity and low wages, we're committed to empowering and professionalizing this essential workforce. After identifying the challenges in formalizing Rwanda's Domestic Service Industry, we aim at outlining a path forward to ensure fair treatment, professional development, and standardized working conditions for domestic workers.</p>
            </div>
            <div className="goal-grid">
                {
                    data.map((goal, index) => {
                        return (
                            <div className="goal" key={index}>
                                <img src={goal.img} alt="goal-icon"/>
                                <h3 className="goal-title">{goal.title}</h3>
                                <p className="goal-desc">{goal.desc}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}