import React from 'react';
import './Waitlist.css';

export default function Waitlist() {
    return (
        <div className='waitlist'>
            <p>Thank you for your interest in HouseHelp! We're excited to match you with a trained and vetted houseworker. Please fill out the short form to join our waitlist and be among the first to access our services.</p>
            <a href='https://forms.gle/VtZKT2fuKxbY2rDCA' className='button' target='_blank' rel='noopener noreferrer'>Register</a>
        </div>
    )
}
