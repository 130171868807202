import React from "react";
import styled from "styled-components";
import './InfoSection.css';

const Section = styled.section`
    width: 100%;
    height: 100%;
    background-color: 'transparent';

    h1 {
        color: var(--dark-blue);
    }
`;

const ColumnLeft = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    line-height: 1.4;
    padding: 1rem 2rem;
    order: ${({ reverse }) => (reverse ? '2' : '1')};

    h1 {
        margin-bottom: 1rem;
        font-size: clamp(1.5rem, 6vw, 2rem);
    }

    p {
        margin-bottom: 2rem;
    }
`;

const ColumnRight = styled.div`
    padding: 1rem 2rem;
    order: ${({ reverse }) => (reverse ? '1' : '2')};
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        order: ${({ reverse }) => (reverse ? '2' : '1')};
    }

    img {
        width: 60%;
        height: 60%;
        object-fit: cover;
        border-radius: 10px;

        @media screen and (max-width: 768px) {
            width: 90%;
            height: 90%;
        }
    }
`;

export default function InfoSection({ section }) {
    return (
        <Section reverse={section.reverse}>
            <div className="section-container">
                <ColumnLeft reverse={section.reverse}>
                    <h1>{section.header}</h1>
                    <p>{section.parOne}</p>
                    {section.parTwo && <p>{section.parTwo}</p>}
                    {section.parThree && <p>{section.parThree}</p>}
                    {section.header1 && <h1>{section.header1}</h1>}
                    {section.parOne1 && <p>{section.parOne1}</p>}
                </ColumnLeft>
                <ColumnRight reverse={section.reverse}>
                    <img src={section.img} alt="" />
                </ColumnRight>
            </div>
        </Section>
    );
}