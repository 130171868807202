import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../images/house-help-white-logo.png";
import './Navbar.css';

export default function Navbar() {
    const [theme, setTheme] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(false);
    const location = useLocation();

    const toggle = () => {
        setMobileMenu(!mobileMenu);
    };

    const changeTheme = () => {
        if(window.scrollY >= 60) {
            setTheme(true);
        } else {
            setTheme(false);
        }
    };

    useEffect(() => {
        const monitorScroll = () => {
            window.addEventListener('scroll', changeTheme);
        };

        monitorScroll();

        return () => {
            window.removeEventListener('scroll', changeTheme)
        };
    }, []);

    let style = {
        backgroundColor: theme || location.pathname !== "/" ? 'var(--dark-blue)' : 'transparent',
        transition: '0.4s'
    };

    return (
        <nav style={style} className="container">
            <Link to="/"><img src={logo} alt="logo" className="logo"/></Link>
            <ul className={mobileMenu ? "" : "hide-mobile-menu"}>
                <li><Link to="/" className="nav-menu-link" onClick={toggle}>Home</Link></li>
                <li><Link to="/about" className="nav-menu-link" onClick={toggle}>About</Link></li>
                <li><Link to="/contact" className="nav-menu-link" onClick={toggle}>Contact Us</Link></li>
            </ul>
            <i className="menu-icon" onClick={toggle} />
        </nav>
    );
}