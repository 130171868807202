import introPic from "../images/intro.jpg";
import visionPic from "../images/vision.jpg";
import missionPic from "../images/mission.jpg";

export const intro = {
    header: "We Are House Help",
    parOne: "House Help Ltd is a Rwandan-based startup dedicated to revolutionizing the domestic service industry. Our mission is to provide reliable, professional, and customer-centric household services while empowering workers and promoting social impact. House Help was born out of a vision to address the inherent deficiencies in the informal domestic service sector in Rwanda.",
    parTwo: "House Help offers a range of household services, including housekeeping, childcare, cooking, and gardening. We connect households with trained and vetted houseworkers. Through our extensive network and rigorous vetting process, we ensure seamless connections, prioritizing ongoing support for clients and workers alike.",
    parThree: "House Help stands out in the market due to our commitment to customer-centricity, worker empowerment, and social impact. Unlike traditional intermediaries, we prioritize transparency, accountability, and fair treatment for both clients and houseworkers, creating a win-win solution for all stakeholders.",
    reverse: false,
    img: introPic
};

export const vision = {
    header: "Our Vision",
    parOne: "To create a future where every household in Rwanda experiences the benefits of reliable, professional, and empowering domestic services, fostering happier homes and stronger communities.",
    reverse: true,
    img: visionPic
};

export const mission = {
    header: "Our Mission",
    parOne: "House Help is dedicated to revolutionizing the domestic service industry in Rwanda by providing a seamless platform that connects households with skilled and vetted houseworkers. We aim to empower workers, enhance the quality of life for families, and drive positive social impact through transparency, fairness, and innovation.",
    reverse: false,
    img: missionPic
};