import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelopeOpen } from "@fortawesome/free-regular-svg-icons";
import "./Profile.css";

export default function Profile({ data }) {
    return (
        <div className="profile-card">
            <div className="profile-img">
                <img src={data.img} alt="profile" />
            </div>
            <div className="profile-desc">
                <span>{data.name}</span>
                <span>{data.position}</span>
                <div className="profile-socials">
                    <a className="social-icon" href={data.linkedin} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                    <a className="social-icon" href={"mailto:" + data.email} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faEnvelopeOpen} />
                    </a>
                </div>
            </div>
        </div>
    );
}