import React from "react";
import Hero from '../Hero';
import Process from '../Process';
import Title from '../Title';
import AboutSection from '../AboutSection';
import Goals from '../Goals';
import Contact from '../Contact';
import Footer from '../Footer';
import { home } from "../Data/HeroData";
import Waitlist from "../Waitlist";

export default function Home() {
    return (
        <div >
            <Hero data={home}/>
            <div className='container'>
                <AboutSection/>
                <Title subTitle={"Our Process"} title={"How We Do It"}/>
                <Process/>
                <Title subTitle={"Our Aim"} title={"Build Something"}/>
                <Goals/>
                <Title subTitle={"Register"} title={"Join Our Waitlist"}/>
                <Waitlist/>
                <Title subTitle={"Contact Us"} title={"Get In Touch"}/>
                <Contact/>
                <Footer/>
            </div>
        </div>
    );
}