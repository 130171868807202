import React from "react";
import Footer from '../Footer';
import { leadership } from "../Data/TeamData";
import { intro, vision, mission } from "../Data/AboutData";
import InfoSection from "../InfoSection";
import FeatureSection from "../FeatureSection";
import Profile from "../Profile";
import styled from "styled-components";

const Wrapper = styled.div.attrs(() => ({ className: 'section-container' }))`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`;

const style = {
    color: 'var(--dark-blue)',
    margin: '80px 0 30px',
    textTransform: 'none',
    textAlign: 'center'
}



export default function About() {
    return (
        <div>
            <InfoSection section={intro} />
            <FeatureSection data={mission} />
            <InfoSection section={vision} />
            <div className="container">
                <h1 style={style}>Our Leadership Team</h1>
                <Wrapper>
                    {
                        leadership.map((leader) => {
                            return <Profile data={leader} />
                        })
                    }
                </Wrapper>
                <Footer />
        </div>
        </div>
    );
}