import React from "react";
import "./FeatureSection.css";

export default function FeatureSection({ data }) {
    return (
        <div className="feature-section">
            <div className="feature-container">
                <div className="feature-wrapper">
                    <div className="left-column">
                        <div className="left-content">
                            <h1>{data.header}</h1>
                            <p>{data.parOne}</p>
                        </div>
                    </div>
                    <div className="right-column">
                        <img src={data.img} alt="feature-img" />
                    </div>
                </div>
            </div>
        </div>
    );
}