import romeo from "../images/romeo.jpg";
import kennedy from "../images/kennedy.jpg";
import eric from "../images/eric.jpg";
import teta from "../images/teta.jpg";
import victor from "../images/victor.jpg";

export const leadership = [
    {
        name: "Romeo Habimana",
        position: "CEO / Co-Founder",
        img: romeo,
        linkedin: "https://www.linkedin.com/in/romeo-habimana-46825b207/",
        email:  "info@househelprw.com"
    },
    {
        name: "Victor Kaberuka Shyaka",
        position: "Business Development / Co-Founder",
        img: victor,
        linkedin: "https://www.linkedin.com/in/victor-kaberuka-shyaka-8691511a3/",
        email:  "victorkaberuka@outlook.com"
    },
    {
        name: "Murielle Teta",
        position: "COO / HR",
        img: teta,
        linkedin: "https://www.linkedin.com/in/teta-murielle-476919207/",
        email:  "tetanicky04@gmail.com"
    },
    {
        name: "Kennedy Rogers Ndahiro",
        position: "Marketing / Client Relations",
        img: kennedy,
        linkedin: "https://www.linkedin.com/in/ndahiro-kennedy-a434a8270/",
        email: "nda.kennedyrogers@gmail.com"
    },
    {
        name: "Eric Hirwa",
        position: "Finance Manager",
        img: eric,
        linkedin: "https://www.linkedin.com/in/eric-hirwa-4442a219b/",
        email:  "rickhirwa@gmail.com"
    }
];