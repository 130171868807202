import React from "react";
import Footer from '../Footer';
import Contact from "../Contact";
import Accordion from "../Accordion";
import Title from '../Title';
import styled from 'styled-components';
import RwandaSvg from "../images/rwanda.svg";

const StyledContactUs = styled.div`
  padding: 4rem 0 1rem;
  background-color: var(--dark-blue);
  background-image: url(${RwandaSvg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const style = {
    color: '#fff',
    margin: '80px 0 20px',
    textTransform: 'none',
    textAlign: 'center'
}



export default function ContactUs() {
    return (
        <div>
            <StyledContactUs>
                <div className="container">
                    <h1 style={style}>Frequently Asked Questions</h1>
                    <Accordion />
                </div>
            </StyledContactUs>
            <div className="container">
                <Title title={"Contact Us"} />
                <Contact />
                <Footer />
            </div>
        </div>
    );
}