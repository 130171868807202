import React from "react";
import { faLinkedinIn, faInstagram, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Footer.css";

export default function Footer() {
    return (
        <div className="footer">
            <p>© {new Date().getFullYear()} House Help. All rights reserved.</p>
            <ul>
                <li>
                    <a className="social-icon" href="https://www.linkedin.com/company/househelprw/about/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                </li>
                <li>
                    <a className="social-icon" href="https://www.instagram.com/househelprw?igsh=cTBudHJsbzN2bHM5" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                </li>
                <li>
                    <a className="social-icon" href="https://x.com/househelprw?s=21&t=_CNLC0O263nMbO1JMhqy_w" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faXTwitter} />
                    </a>
                </li>
            </ul>
        </div>
    );
}