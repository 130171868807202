import customerExperience from "../images/customer-experience.png";
import chart from "../images/chart-icon.png";
import socialImpact from "../images/social-impact.png";

export const data = [
    {
        title: "Customer Experience",
        desc: "Our dedication to responsiveness, attention to detail, and proactive problem-solving ensures that customers feel valued and satisfied with our service.",
        img: customerExperience
    },
    {
        title: "Employee Empowerment",
        desc: "With personalized training modules, mentorship opportunities, and access to resources, we equip domestic workers with the knowledge and confidence to excel in their roles.",
        img: chart
    },
    {
        title: "Driving Social Impact",
        desc: "By providing employment opportunities to rural workers, we contribute to inclusivity and equality, poverty alleviation, and economic empowerment.",
        img: socialImpact
    }
];