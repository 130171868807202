import React from "react";
import "./Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { PhoneInput } from 'react-international-phone';

export default function Contact() {
    const [result, setResult] = React.useState("");
    const [phone, setPhone] = React.useState("");

    const onSubmit = async (event) => {
        event.preventDefault();
        setResult("Sending....");
        const formData = new FormData(event.target);

        formData.append("access_key", "b3414ad3-fd64-4e86-894a-c19bd61c8a41");

        const response = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData
        });

        const data = await response.json();

        if (data.success) {
            setResult("Form Submitted Successfully");
            event.target.reset();
        } else {
            console.log("Error", data);
            setResult(data.message);
        }
    };

    return (
        <div className="contact" id="contact">
            <div className="contact-col">
                <h3>Leave us a message</h3>
                <p>
                    Feel free to leave a message through the message form or use our contact information to reach us.
                    Your feedback, questions, and suggestions are important to us as we strive to provide exceptional service to our clients.
                </p>
                <ul>
                    <li><FontAwesomeIcon icon={faEnvelope} className="contact-icon" /> hello@househelprw.com</li>
                    <li><FontAwesomeIcon icon={faPhone} className="contact-icon" /> +250 786010448</li>
                    <li><FontAwesomeIcon icon={faLocationDot} className="contact-icon" /> Kigali, Rwanda</li>
                </ul>
            </div>
            <div className="contact-col">
                <form onSubmit={onSubmit}>
                    <label>Name</label>
                    <input type="text" name="name" placeholder="Enter Your Name" required />
                    <label>Email</label>
                    <input type="email" name="email" placeholder="Enter Your Email" required />
                    <label>Phone Number</label>
                    <PhoneInput value={phone} onChange={(value) => setPhone(value)} defaultCountry="rw" forceDialCode={true} name="phone-number" placeholder="Enter Your Phone Number" required />
                    <label>Your Message</label>
                    <textarea name="message" rows="6" placeholder="Enter Your Message" required></textarea>
                    <button type="submit" className="button">Send</button>
                </form>
                <span>{result}</span>
            </div>
        </div>
    );
}