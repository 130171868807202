import React from "react";
import stepOne from "../images/cleaning1.jpg";
import stepTwo from "../images/matching.jpg";
import stepThree from "../images/support.jpg";
import training from "../images/training-icon.png";
import matching from "../images/match-icon.png";
import support from "../images/support-icon.png";
import './Process.css';

export default function Process() {
    return (
        <div className="process">
            <div className="step">
                <img src={stepOne} alt="cleaning"/>
                <div className="caption">
                    <img src={training} alt="training" />
                    <p>Train agents</p>
                </div>
            </div>
            <div className="step">
                <img src={stepTwo} alt="handshake"/>
                <div className="caption">
                    <img src={matching} alt="partnering" />
                    <p>Match agents with clients</p>
                </div>
            </div>
            <div className="step">
                <img src={stepThree} alt="customer-support"/>
                <div className="caption">
                    <img src={support} alt="support" />
                    <p>Continuously support both parties</p>
                </div>
            </div>
        </div>
    );
}