export const data = [
    {
        question: "What services does House Help provide?",
        answer: "House Help provides professional, reliable, and personalised home care services to families and individuals."
    },
    {
        question: "What qualifications do our domestic workers have to ensure high-quality service?",
        answer: "Our domestic workers are rigorously trained and supported to deliver services with professionalism, integrity, and compassion."
    },
    {
        question: "How does House Help's unique pairing process ensure a harmonious relationship between clients and domestic workers?",
        answer: "We match clients and domestic workers based on clients' needs and preferences for a seamless fit."
    },
    {
        question: "What kind of customer support can I expect from House Help?",
        answer: "Expect responsive, 24/7 customer care with personalised support and a commitment to your satisfaction."
    },
    {
        question: "How is House Help making a difference through its commitment to social causes?",
        answer: "We aim to create rural employment opportunities, reducing unemployment, and empowering individuals and communities for a more equitable and sustainable society."
    }
];